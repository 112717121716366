// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/YujinXian.jpg";
// Tech stack images
import python from "./assets/techstack/icons8-python-96.png";
import r from "./assets/techstack/icons8-r-96.png";
import scala from "./assets/techstack/icons8-scala-a-general-purpose-programming-language-with-strong-static-type-system-96.png";
import pytorch from "./assets/techstack/icons8-pytorch-96.png";
import tensorflow from "./assets/techstack/icons8-tensorflow-96.png";
import hadoop from "./assets/techstack/icons8-hadoop-distributed-file-system-96.png";
import spark from "./assets/techstack/apache_spark_logo_icon_170561.png";
import aws from "./assets/techstack/icons8-amazon-web-services-96.png";
import gcp from "./assets/techstack/icons8-google-cloud-96.png";
import powerbi from "./assets/techstack/icons8-power-bi-2021-96.png";
import tableau from "./assets/techstack/icons8-tableau-software-96.png";
import fastapi from "./assets/techstack/fastapi-1.svg";
import postgre from "./assets/techstack/icons8-postgresql-96.png";
import mongdb from "./assets/techstack/icons8-mongodb-96.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import postman from "./assets/techstack/postman-icon.png";
// Porject Images
import projectImage1_1 from "./assets/projects/project1/project1_1.jpg";
import projectImage1_2 from "./assets/projects/project1/project1_2.jpg";
import projectImage2_1 from "./assets/projects/project2/project2_1.jpg";
import projectImage2_2 from "./assets/projects/project2/project2_2.jpg";
import projectImage3_1 from "./assets/projects/project3/project3_1.jpg";
import projectImage3_2 from "./assets/projects/project3/project3_2.jpg";
import projectImage3_3 from "./assets/projects/project3/project3_3.jpg";
import projectImage3_4 from "./assets/projects/project3/project3_4.jpg";
import projectImage4_1 from "./assets/projects/project4/project4_1.png";
import projectImage4_2 from "./assets/projects/project4/project4_2.png";
import projectImage4_3 from "./assets/projects/project4/project4_3.png";
import projectImage5_1 from "./assets/projects/project5/project5_1.png";
import projectImage5_2 from "./assets/projects/project5/project5_2.png";
import projectImage6_1 from "./assets/projects/project6/project6_1.PNG";
import projectImage6_2 from "./assets/projects/project6/project6_2.PNG";
import projectImage6_3 from "./assets/projects/project6/project6_3.PNG";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: " Yujin Xian",
  tagline: "AI Engineer / Data Scientist",
  img: profile,
  about: `Experienced AI Engineer / Data Scientist with 6+ years of experience and a strong background in designing and implementing data processing workflows and AI models. Proficient in various data science technologies such as data mining, data storage, data analysis and visualization. Experienced in developing various AI projects using deep learning models like NLP, STT/TTS, lip-sync and Deepfake.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  github: "https://www.github.com/LoveNui"
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "AI Engineer",
    Company: `Lamdba Vision`,
    Location: "France",
    Type: "Full Time",
    Duration: "March 2023 – September 2023",
    Achievement: [
      "Developed AI project that create customer clone brain with customer LinkedIn profile or bio text using GPT3.5, Fine-tuning, scarping technologies.",
      "Designed and developed video generation AI project that generated video of customer saying with their voice and image, using lip sync, TTS, fine-tuning, STT.",
      "Improved TTS model performance by optimizing the bark model, resulting in a TTS speed reduction of 8s.",
      "Optimized Sadtalker model through model splitting, reorganization and TensorRT, resulting in generation speed reduced to 15s"
    ],
  },
  {
    Position: "Data Scientist",
    Company: `Keppel Data Centres`,
    Location: "Hong Kong",
    Type: "Full Time",
    Duration: "April 2020 – March 2023",
    Achievement: [
      "Developed and implemented a machine learning model to generate SQL from a natural language using a Transformer, resulting 10% increase in customers.",
      "Consolidated 33+ SQL Server databases into 11 databases and merged hardware using KNIME and Apache Spark, reducing 60% of the original cost of hardware acquisition.",
      "Designed ETL pipeline to collect and analyze customer data from several digital marketing websites, resulting increase of 10% in customers.",
      "Implemented Naive Bayes, Clustering, and Classification in Python to analyze 100GB of data, streamlining other machine- learning techniques.",
      "Led a team of 2 data analysts and 1 data quality expert in developing marketing analysis system to collect data and analyze and predict market states."
    ],
  },
  {
    Position: "Data Analyst",
    Company: `IT Square Limited`,
    Location: "Hong Kong",
    Type: "Full Time",
    Duration: "May 2017 – March 2020",
    Achievement: [
      "Conducted A/B testing to optimize marketing campaigns and increase conversion rates by 10%.",
      "Designed and developed web pages using SQL, PostgreSQL, React and NodeJS, resulting in real-time support and a 50% increase in usability.",
      "Extracted business data from 10+ websites, analyzed and reported CEOs, making correct decisions and revenue of $150K.",
      "Created interactive dashboards using Microsoft Power BI to visualize key performance indicators and provide insights to stakeholders.",
      "Created 10+ dashboards designed to ensure clarity, beauty and comfort."
    ],
  }
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "B.S. in Computer Science",
    Company: `Hong Kong University of Science and Technology`,
    Location: "Hong Kong",
    Type: "Full Time",
    Duration: "2013 - 2017",
  },
];

// Tech Stack and Tools
export const techStackDetails = [
  python,  
  r,
  scala,
  pytorch,
  tensorflow,
  hadoop,
  spark,
  powerbi,
  tableau,
  fastapi,
  postgre,
  mongdb,
  aws,
  gcp
];

export const toolStackDetails = [
  vscode,
  github,
  git,
  postman,
];

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Quivr - Expert Clone Brain",
    summary:`Quivr, your second brain, utilizes the power of GenerativeAI to store and retrieve unstructured information. Think of it as Obsidian, but turbocharged with AI capabilities.`,
    image: [projectImage1_1, projectImage1_2],
    description: `This project extracts customer information by scraping Linkedin or semantically analysing customer bio text, and uses this data to fine-tune the LLM model and clone the customer brain.`,
    challenge: `Develop cloned personal chat-bot`,
    solution:[`Fune-tuning GPT3.5 with customer data, real response as streaming method`],
    techstack_summary: 'NLP, LLM, Scraping, Fine tuning ...',
    techstack: 'NLP, LLM, Scraping, Fune-tuning, FastAPI, OpenAI, Python, Shell',
    previewLink: "https://clone.mygpt.fi/",
    githubLink: "https://github.com/LoveNui/exportbrainGPT",

  },
  {
    title: `Clipsset`,
    summary:`Clipsset, combination deepfake and text-to-speech, AI project that generated a video of someone saying something from a script and an image`,
    image: [projectImage2_1, projectImage2_2],
    description: `It clones your voice and generates video responses that perfectly mimic your tone and style. It clones your voice and generates audio from a script based on it, and it generates video of you saying it with that audio and your image.`,
    challenge: `Improve the generation speed`,
    solution:[`Optimized the bark model using TensorRT, reducing generation time by 10s`, `Tensorrt overcame the challenge of optimizing the unsupported sadtalker model, resulting in an additional reduction of 15s in generation time.`],
    techstack_summary: 'TTS, DeepFake, FastAPI, Model O...',
    techstack: 'TTS, DeepFake, Model Optimization, Fine tuning, FastAPI, Python, Shell',
    previewLink: "https://dev.timetoken.me",
    githubLink: "https://github.com/LoveNui/video-api-bark-sadtalker",
  },
  {
    title: "SecondMe",
    summary:`SecondMe, a combination of Quivr and Clipsset, a telegram chatbot that speaks through text and voice.`,
    image: [projectImage3_1, projectImage3_2, projectImage3_3, projectImage3_4],
    description: `Using cloned brain and voice/picture in Quivr and Chipssets, create telegram bots. These bot can answer with your clone brain and response video of your saying with your voice and picture.`,
    challenge: `Control muitl bots`,
    solution:[`Develop docker container manangement code(auto create and control bot containers) to optimize bot management`],
    techstack_summary: 'Telegram Bot, STT, FastAPI, Docker...',
    techstack: 'Telegram Bot, STT, FastAPI, Docker, Python',
    previewLink: "https://r2d2.mygpt.fi/",
    githubLink: "https://github.com/LoveNui/telegram-bot-channel",
  },
  {
    title: "ETL-IntensiveCareUnit",
    summary:`This project extracts and transforms mock data generated by IoT devices installed on patient beds in real-time from an ICU environment.`,
    image: [projectImage4_1, projectImage4_2, projectImage4_3],
    description: `A comprehensive ETL solution leveraging Apache NiFi, Kafka, MongoDB, and Metabase.`,
    challenge: `Streaming processing, robustness and anomaly detection`,
    solution:[`Utilize the power of Apache NiFi to create, automate, and manage data flows within the ETL pipeline`, `Facilitate reliable, real-time data streaming and event processing with Apache Kafka`, `Easily deploy and manage the entire ETL application with Docker containers.`],
    techstack_summary: 'Apache NiFi, Kafka, AI, MongDB, ...',
    techstack: 'Apache NiFi, Kafka, MongoDB, Metabase, Docker, AI, Python',
    previewLink: "",
    githubLink: "https://github.com/LoveNui/ETL-IntensiveCareUnit",
  },
  {
    title: "Marketing Analysis",
    summary:`This project analyzes Amazon Vine program reviews, specifically focusing on the TV review dataset.`,
    image: [projectImage5_1, projectImage5_2],
    description: `This project analyzes Amazon Vine program reviews, specifically focusing on the TV review dataset. It uses PySpark for ETL processes and examines potential bias in favorable reviews from Vine members.`,
    challenge: `Streaming processing, robustness and anomaly detection`,
    solution:[`Compare the number of 1 star reviews between Vine and Unpaid to determine any additional patterns`, `Filter the Vine and Unpaid review datasets by verified purchase to add credibility to our review sample analysis`],
    techstack_summary: 'Apache Spark, Google Colab, AW...',
    techstack: 'Apache Spark, Google Colab, AWS RDS, AWS S3, PGAdmin',
    previewLink: "",
    githubLink: "https://github.com/LoveNui/Marketing_Analysis-AWS-Spark-SQL",
  },
  {
    title: "Twinhub",
    summary:`Chat with AI twins of your favourite influencers now! Exclusive content and personalised message just for you 😘`,
    image: [projectImage6_1, projectImage6_2, projectImage6_3],
    description: `TwinHub allows you to chat with you favourite influencers / celebrities and get exclusive contents (📸/ 📹 / 🎤) and personalised messages from them.`,
    challenge: `Clone a few people, support a real-time virtual call`,
    solution:[`Fine-tuning GPT3.5 with some people's detailed information`, `Support virtual call using twilio`],
    techstack_summary: 'GPT3.5, Twilio, Elevenlabs, Fi...',
    techstack: 'GPT3.5, Twilio, Elevenlabs, Fine-tuning, Deepgram, LangChain',
    previewLink: "https://call.twinhub.ai/index",
  },
];

// Enter your Contact Details here
export const contactDetails = {
  Email: "yujinxian40@gmail.com",
  Telegram: "https://t.me/lovenui",
  Discord:"@lovenui",
  Skype:"live:.cid.338ce0ac5d7bc213"
};

export const Profile ={
  Profile: profile
}