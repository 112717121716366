import React from "react";
import { techStackDetails, toolStackDetails } from "../Details";

function Technologies() {
  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
      <section>
        <h1 className="text-2xl text-light-heading light:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
        <p className="text-content py-2 lg:max-w-3xl">
          Technologies I've been working with recently
        </p>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        {techStackDetails.map(
          (skill)=>(
          <img src={skill} className="h-[96px]" alt="My core Skills"/>
        ))}
      </section>
      <section>
        <h1 className="text-2xl pt-10 text-light-heading light:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        {toolStackDetails.map(
          (skill)=>(
          <img src={skill} className="h-[96px]" alt="My Main tools" />
        ))}
      </section>
    </main>
  );
}

export default Technologies;
