import React from "react";
import { contactDetails } from "../Details";

function Contact() {
  const { Email, Telegram, Skype, Discord} = contactDetails;
  return (
    <main className="container mx-auto max-width mt-16">
      <h1 className="text-center text-2xl md:text-3xl lg:text-6xl text-light-heading light:text-light-heading font-semibold md:font-bold">
        For any questions please drop a mail
      </h1>
      <h3 className="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-5 md:pt-10 md:pb-6">
        <a href={`mailto:${Email}`}>{Email}</a>
      </h3>
      <h3 className="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-5 md:pt-10 md:pb-6">
        <a href={Telegram} target="_blank" rel="noreferrer">{Telegram}</a>
      </h3>
      <h3 className="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-5 md:pt-10 md:pb-6">
        <a href="https://web.skype.com/" target="_blank" rel="noreferrer">{Skype}</a>
      </h3>
      <h3 className="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-5 md:pt-10 md:pb-6">
        <a href="https://discord.com/channels/@me" target="_blank" rel="noreferrer">{Discord}</a>
      </h3>
    </main>
  );
}

export default Contact;
